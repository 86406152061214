import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Box } from 'theme-ui'

const VideoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  width: 100%;
  height: 100%;
`;

const VideoRow = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
`;

const VideoBlock = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-flow: row wrap;
`;

const VideoFrame = ({ videoId }) => {
  return (
    <Box
      sx={{ width: '100%', height: '100%' }}
      as='iframe'
      title="Che cos'è Together Price"
      src={`https://www.youtube.com/embed/${videoId}?feature=oembed`}
      loading="lazy"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  );
};

const Video = ({ styles: videoStyles = {}, videoId = 'R4R5zBkdnBo' }) => (
  <VideoContainer sx={videoStyles.root}>
    <VideoRow sx={videoStyles.row}>
      <VideoBlock sx={videoStyles.block}>
        <VideoFrame videoId={videoId} />
      </VideoBlock>
    </VideoRow>
  </VideoContainer>
);

export default Video;
